import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Box, Heading, Flex, Icon } from "@chakra-ui/react"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import Layout from "../components/layout"
import Banner from "../components/chinese/banner"
import Strategies from "../components/chinese/strategies"
import Portfolios from "../components/chinese/portfolios"
import AboutUs from "../components/chinese/aboutUs"
import SEO from "react-seo-component"


import Header from "../components/chinese/header_phone"
import ResponsivePlayer from '../components/minPlayer/minPlayer'
import minvideo from '../assets/minvideo.mp4'

const CN = () => {
  const data = useStaticQuery(graphql`
    {
      placeholderImage: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 825
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      backgroundImage1: file(relativePath: { eq: "bg1.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 490
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const banner = getImage(data.placeholderImage)

  const bg1 = getImage(data.backgroundImage1)

  return (
    <Layout>

      {/* 缩小的手机导航栏 */}
    <Header />

      <SEO
        title={"首页"}
        titleTemplate={"澳门元宇宙产业协会（MIAM）"}
        titleSeparator={`-`}
        description={"澳门元宇宙产业协会（MIAM）致力于支持元宇宙产业的发展；促进相关教育及创新活动，建立和鼓励社群互动，与志同道合者们共同探讨元宇宙所带来的技术与文化革新。"}
        // image={"https://i.ibb.co/6RWBX1d/Mindfulness-Capital.png"}
        image={"https://i.ibb.co/sJwr3F6/miam-logo.png"}
        pathname={"cn"}
        siteLanguage={"cn"}
        siteLocale="cn_gb"
        twitterUsername={"澳门元宇宙产业协会（MIAM）"}
      />

<Box mt="4rem" />
    {/* first row  */}
      {/* pc */}
      <Box maxW="1100px" margin="0 auto" padding='0 auto' 
          // h={{md:'36rem'}}
          display={{base: "none",sm: "none",md: "flex"}}
      >
            <ResponsivePlayer  url={minvideo} controls={false}  playing={true} isCenterVideo={false}/>
         </Box>
      {/* phone */}
      <Box maxW="1100px" margin="0 auto" padding='0 auto' 
          // h={{sm:'20rem'}}
          display={{base: "flex",sm:"flex", md: "none"}}
      >
            <ResponsivePlayer  url={minvideo} controls={true}  playing={false} isCenterVideo={false}/>
         </Box>


      {/* <BgImage image={banner}>
        <Banner />
      </BgImage> */}

      {/* second row  */}
      <Box maxW="1100px" margin="0 auto" mt="4rem">
        <BgImage image={bg1}>
          <Strategies />
        </BgImage>
      </Box>

      {/* third row */}
      <Box maxW="1100px" margin="0 auto">
        <Portfolios />
      </Box>

      {/* fourth row  */}
      <Box maxW="1100px" margin="0 auto">
        <AboutUs />
      </Box>
    </Layout>
  )
}

export default CN
