import { Box, Text, Heading } from "@chakra-ui/react"
import React from "react"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"

import "../../style/swiper.css"

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core"

import { StaticImage } from "gatsby-plugin-image"

// install Swiper modules
SwiperCore.use([Pagination])

const Strategies = () => {
  return (
    <Box maxW="1240px" margin="0 auto" mx={8}>
      {/* title  */}
      <section id="Strategies">
        <Heading
          pt={7}
          color="#333"
          fontSize={{ base: "xl", md: "2xl" }}
          borderBottom="4px solid #E2E8F0"
        >
          <Box as="span" borderBottom="4px solid #2B6CB0" letterSpacing="tight">
            协
          </Box>
          会介绍
        </Heading>
      </section>

      <Box pt={{ base: "10" }} pb={{ base: "10" }}>
        <br />
        <Text>
          澳门元宇宙产业协会（MIAM）致力于支持元宇宙产业的发展，促进相关教育及创新活动，建立和鼓励社群互动，与志同道合者们共同探讨元宇宙所带来的技术与文化革新。
        </Text>
        <br />
      </Box>
    </Box>
  )
}

export default Strategies
