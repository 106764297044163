import { Box, Grid, Heading, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const AboutUs = () => {
  return (
    <Box maxW="1240px" margin="0 auto" mx={8} mb={20}>
      {/* title  */}
      <section id="About">
        <Heading
          pt={7}
          color="#333"
          fontSize={{ base: "xl", md: "2xl" }}
          borderBottom="4px solid #E2E8F0"
        >
          <Box as="span" borderBottom="4px solid #2B6CB0">
            会
          </Box>
          员介绍
        </Heading>
      </section>
      {/* thomas */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
          // md: "repeat(2, 1fr)",
          // lg: "repeat(2, 1fr)",
          // xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        {/* <StaticImage
          src="../../images/ThomasAo.png"
          alt="A dinosaur"
          placeholder="blurred"
          // layout="fixed"
          // width={568}
          // height={334}
        /> */}

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            // fontSize={{ sm: "xl", md: "xl", lg: "3xl", xl: "3xl" }}
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Thomas Ao 欧嘉成
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            澳门元宇宙产业协会创始人、会长，Thomas作为正念资本创始合伙人和资深投资人，专注于元宇宙、区块链技术、NFT和Web
            3. 0等领域的投资。
          </Text>
        </Box>
      </Grid>
      {/* fernando */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
          // md: "repeat(2, 1fr)",
          // lg: "repeat(2, 1fr)",
          // xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        {/* <StaticImage
          src="../../images/Fernandp.png"
          alt="A dinosaur"
          placeholder="blurred"
          // layout="fixed"
          // width={568}
          // height={334}
        /> */}

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            // fontSize={{ sm: "xl", md: "xl", lg: "3xl", xl: "3xl" }}
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Fernando Pereira 田锡恩
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            澳门元宇宙产业协会联合创始人，GROW UP
            ESPORTS创始合伙人，以及国际电子竞技联合会的正式会员和澳门代表。
          </Text>
        </Box>
      </Grid>

      {/* terry */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
          // md: "repeat(2, 1fr)",
          // lg: "repeat(2, 1fr)",
          // xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        {/* <StaticImage
          src="../../images/TerryYeung.png"
          alt="A dinosaur"
          placeholder="blurred"
          // layout="fixed"
          // width={568}
          // height={334}
        /> */}

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            // fontSize={{ sm: "xl", md: "xl", lg: "3xl", xl: "3xl" }}
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Terry Yeung 杨东成
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            澳门元宇宙产业协会联合创始人，帝国科技集团(0776.HK)的执行董事，致力于为全球社群及玩家提供开放的GameFi平台。
          </Text>
        </Box>
      </Grid>
    </Box>
  )
}

export default AboutUs
